<template>
  <div class="col c-support-bar__section">
        <div class="c-support-bar__content">
            <div class="h6 c-support-bar__content-title">{{name}}</div>
            <button v-b-toggle="modalId" class="btn btn-link-plain btn--select">
                <div class="h6">{{name}}</div>
            </button>
            <b-collapse :id="modalId" :visible="false">
                <slot>
                    
                </slot>
            </b-collapse>
        </div>
    </div>
</template>
<script>
    import uniqid from 'uniqid';
    export default {
        name: 'SupportBarSection',
        props: ['name'],
        data() {
            return {
                modalId: uniqid() + '__footer_support_bar_',
            }
        },
    }
</script>