import Vue from 'vue'
// import BootstrapVue from 'bootstrap-vue'

import { ButtonPlugin, CollapsePlugin } from 'bootstrap-vue'

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue'

import speedBump from '@/directives/speed-bump';
Vue.directive('speed-bump', speedBump);

import SupportBarSection from '@/components/footer-support-bar-section.vue';
Vue.component('support-bar-section', SupportBarSection);


// Styles
import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import './sass/style.scss'

// Vue.use(BootstrapVue);
Vue.use(CollapsePlugin)
Vue.use(ButtonPlugin)

Vue.config.productionTip = false
// Telling vue to ignore components that doesn't need for header and footer
Vue.config.ignoredElements = [/aha-modal/, /aha-mobile-nav/]

window.phd_syn_footer = new Vue({
  // render: h => h(Footer, {props: {showSupport: this.getAttribute('show-support')}})
  render: h => h(Footer),
}).$mount('#phd_syn_footer');

window.phd_syn_header = new Vue({
  render: h => h(Header)
}).$mount('#phd_syn_header')
