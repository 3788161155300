// Header Object
const headerObject = () => {
    return {
      "name":"SyndicationHeaderFooter",
      "displayName":"SyndicationHeaderFooter",
      "pageEditing":false,
      "rootPlaceholderKey":"$root",
      "fields":{
         "NavigationTitle":"Membership",
         "Title":"Syndication Header Footers",
         "Datasource Folder":"{5E727D8E-9AB5-4E8B-ADE8-3C150FC9B6DE}"
      },
      "placeholders":{
         "header":"\r\n    <div class=\"l-header__top\">\r\n        <div class=\"container container-fluid container-wide\">\r\n            <nav class=\"c-top-nav js-mobile-nav-positioning\" role=\"navigation\" aria-label=\"Top Navigation\">\r\n                <a href=\"https://sitecoredevphdcm.heart.org:443/en\" class=\"c-top-nav__logo\">\r\n                        <img src=\"/-/media/project/phd/logos/750x150_phdlogo_2019.png?h=150&amp;w=751&amp;hash=BB0AF3042362FC4F1469AF4F9F58FD3DA7921D43\" alt=\"Professional Heart Daily\" disablewebedit=\"False\">\r\n                </a>\r\n\r\n\r\n\r\n\r\n                <!--Mobila Navigation-->\r\n                <aha-mobile-nav>\r\n                    <template>\r\n                        <ul class=\"c-mobile-nav__list\">\r\n                            \r\n                        </ul>\r\n                    </template>\r\n                </aha-mobile-nav>\r\n            </nav>\r\n        </div>\r\n    </div>\r\n"
      }
        
     }
}

 export default headerObject