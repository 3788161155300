<template>
<div id="phd_syn_footer" class="l-footer">
    <component :is="foot"></component>
</div>
</template>

<script>
import axios from 'axios';
import FooterObject from '@/utility/dataObj';

export default {
    name: 'Footer',
    props: {
        showDisclaimer: {
            type: Boolean,
            default: false
        },
        showSupport: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            endpoint: ['data/data.json', 'https://sitecoredevphdcm.heart.org/x-sitecore-service/layoutservice/contentviaplaceholder?route=/&placeholder=footer'],
            footerTemplate: ``,
            baseImgUrl: {
                dev: 'https://sitecoredevphdcm.heart.org',
                test: 'https://sitecoretestphdcm.heart.org',
                prod: 'https://professional.heart.org', //tentative
            },
            count: 0,
            dataObj: FooterObject
        }
    },
    methods: {
        fetchData(url) {
            axios.get(url, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                    },
                })
                .then((response) => {
                    /* eslint-disable */
                    const { footer } = response.data.placeholders
                    this.footerTemplate = footer
                })
                .catch((error) => {
                    /* eslint-disable */
                    console.log(error);
                });
        },
        hideElement: (element) => {
            let el = document.querySelector(element);
            el.classList.add('d-none');
        },

       
    },
    created() {
        this.footerTemplate = this.dataObj().placeholders.footer //cached version
        
    },
    computed: {
        foot() {
            return {
                template:`<div>${this.footerTemplate}</div>`,
                components: {}
            }
        }
    },
    mounted() {


        if(this.showDisclaimer === false) {
            const disEl = document.querySelector('.c-disclaimer-bar')
            if (disEl) {
                this.hideElement('.c-disclaimer-bar');
            }
        }

        if(this.showSupport === false) {
            const supportEl = document.querySelector('.c-support-bar')
            if (supportEl) {
                this.hideElement('.c-support-bar');
            }
        }
        
        const footer = document.querySelector('.l-footer')
        const logo = footer.querySelector('.l-footer__logo img')

        //logo.setAttribute('src', `${this.baseImgUrl.prod}`+logo.attributes["src"].value)

        const social_list = document.querySelectorAll('ul.l-icon-nav')
        const social_items = social_list[0].children
        /*for(let i = 0; i < social_items.length; i++ ) {
            let el = social_items[i].getElementsByTagName('IMG')
            let attr = el[0].attributes["src"].value
            el[0].src = this.baseImgUrl.prod + '' + attr
        }*/

        // Second icon set, prepend domain url
        const icon_items = social_list[1].querySelectorAll('li');
        // console.log(icon_items);
        /*for(let i = 0; i < icon_items.length; i++ ) {
            let el = icon_items[i].getElementsByTagName('IMG')
            let attr = el[0].attributes["src"].value
            el[0].src = this.baseImgUrl.prod + '' + attr
        }*/
    }
    
}
</script>

