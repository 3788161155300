<template>
<div id="phd_syn_header">
    <component :is="header"></component>
</div>
</template>

<script>
import HeaderObject from '@/utility/headerObj';

export default {
    name: 'Header',
    data() {
        return {
            headerTemplate: ``,
            dataObj: HeaderObject,
            imgDomain: {
                dev: 'https://sitecoredevphdcm.heart.org',
                test: 'https://sitecoretestphdcm.heart.org',
                prod: 'https://professional.heart.org'
            }
        }
    },
    methods: {
    },
    created() {
        this.headerTemplate = this.dataObj().placeholders.header //cached
        
    },
    computed: {
        header() {
            return {
                template:`${this.headerTemplate}`,
                components: {}
            }
        }
    },
    mounted() {
            let logo = document.querySelector('#phd_syn_header nav a img');
            let imgSrc = logo.getAttribute('src');
            logo.setAttribute('src', this.imgDomain.prod + imgSrc);
    },
}
</script>

